











































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {userAPI} from "@/api/users";
import {mapGetters} from "vuex";
import {envHelper} from "@/EnvHelper";
import {EventBus} from "@/EventBus";
import router from "@/router";

@Component({
    components: {},
    computed: {
        ...mapGetters('user', [
            'user',
        ])
    },
})
export default class Login extends Vue {

    envHelper = envHelper

    private email: string = ''
    private error: string = ''
    private state: number = 0
    private verifyLink: string = ''

    get isLoading() {
        return this.state == 1
    }

    get Disabled() {
        return !this.email.length || this.isLoading
    }

    get isLoginSuccess() {
        return this.state == 2
    }

    private login(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        let title = 'Complimenti'
        let text = `Ti abbiamo inviato un link all\'indirizzo ${this.email}, seguilo per accedere all\'area personale.`

        localStorage.setItem('user_mail', this.email);
        this.state = 2;
        userAPI.sendAuthLink(this.email).then(() => {

        }).catch(()=>{
            title = 'Spiacenti'
            text = `Si è verificato un problema durante l'autenticazione. Riprova più tardi.`
        })
        EventBus.$emit?.('showHelp', { title, text })
    }
}
