








































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {userAPI} from "@/api/users";
import Stepper from "@/components/Stepper.vue";
import Card from "@/components/Card.vue";
import CardInput from "@/components/CardInput.vue";
import {mapActions, mapGetters} from "vuex";
import {utility} from "@/utility";


@Component({
  components: {
        Stepper,
      Card,
      CardInput
  },
  computed: {
    ...mapGetters('user', [
      'isAuth'
    ]),
    ...mapGetters('path', [
        'path', 'step'
    ])
  },
  methods: {
      ...mapActions('path', [
          'reset',
      ])
  }
})

export default class SignUp extends Vue {

  private email = ''
  private conf_email = ''
  private privacy = false
  private forceLoader = false
  private path: any
  private step: any

  private state = 0;
  private isAuth: any;
  private error: any;

  get isLoading() {
    return this.state == 1
  }

  created() {
    if (this.isAuth) {
      this.forceLoader = true
      //@ts-ignore
      this.reset();
      let url = utility.getUrlFromState(this.path, this.step)
      this.$router.push(url)
    }
  }

  get Border() {
    if (this.conf_email) {
      return this.email != this.conf_email ? 'border-danger' : 'border-success'
    }
    return '';
  }

  get Disabled() {
    return !this.email || this.email != this.conf_email || !this.privacy
  }

  private async signUp(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.state = 1;
    userAPI.signUp(this.email).then(async response => {
      await this.$store.dispatch('user/setToken', response.data.token)
      return this.$store.dispatch('user/setUser', response.data)
    }).then(res => {
      return this.$router.push({name: 'Dibrief'})
    }).catch((response: any) => {
      this.state = 0;
      this.error = response?.response?.data?.message || 'Signup Error'
    })
  }
}
